import { createApp } from 'vue';

import App from './App.vue';
import alert from '@/hooks/alert';
import router from './router';
import PrimeVue from 'primevue/config';
import axios from 'axios';

const app = createApp(App);

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
app.use(router);
app.use(alert);
app.use(PrimeVue, {
    locale: {
        dayNames: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthNames: ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"],
        monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun","Jul", "Ago", "Set", "Out", "Nov", "Dez"],
        today: "Hoje",
        weekHeader:"Sm",
        firstDayOfWeek:	0,
        dateFormat:	"mm/dd/yy",
        weak: "Semana"
    }
});

app.mount('#app');
