import { reactive, watch } from "vue";
import UserDTO from '@/dtos/UserDTO';
import $http from '@/plugins/http';
import { ClientDTO } from "@/dtos/ClientDTO";

interface MainViewModelState {
    user: UserDTO | null;
    client: ClientDTO | null;
}

class MainViewModel {

    private state = reactive<MainViewModelState>({
        user: null,
        client: null,
    });

    constructor() {
        // Carregar formulário armazenado
        const userString = localStorage.getItem('EP_ADMIN_USER');
        const userBackup = userString ? JSON.parse(userString) as UserDTO : null;

        const clientString = localStorage.getItem('EP_CLIENT');
        const clientBackup = clientString ? JSON.parse(clientString) as ClientDTO : null;

        if(userBackup && userBackup.roleDescription == 'ADMIN') {
            this.user = userBackup;
            if(userBackup.roleDescription == 'ADMIN') {
                $http.defaults.headers['Authorization'] = "Bearer " + userBackup.token;
            }
        }

        if(clientBackup) {
            this.client = clientBackup;
        }

        watch(() => this.state.user, () => {
            localStorage.setItem('EP_ADMIN_USER', JSON.stringify(this.state.user));    
        });

        watch(() => this.state.client, () => {
            localStorage.setItem('EP_CLIENT', JSON.stringify(this.state.client));    
        });
    }

    // Usuário
    public get user() {
        return this.state.user;
    }
    public set user(value: UserDTO | null) {
        this.state.user = value;
    }

    // Cliente
    public get client() {
        return this.state.client;
    }
    public set client(value: ClientDTO | null) {
        this.state.client = value;
    }

    public logout() {
        $http.defaults.headers["Authorization"] = process.env.VUE_APP_HTTP_TOKEN;
        
        this.state.user = null;
        this.state.client = null;

        window.location.reload();
    }
}

export default new MainViewModel();