import { createRouter, createWebHistory, NavigationGuard, RouteRecordRaw } from 'vue-router';
import vm from '@/viewModels/MainViewModel';

const LoginMainView = () => import(/* webpackChunkName: "login" */  '@/views/login/LoginMainView.vue');
const RedirectView = () => import(/* webpackChunkName: "login" */  '@/views/redirect/RedirectView.vue');

// ============= Admin =============
const AdminMainPage = () => import(/* webpackChunkName: "admin" */ '@/views/admin/AdminMainPage.vue');
const MenuPage = () => import(/* webpackChunkName: "admin" */ '@/views/admin/MenuPage.vue');
const ExamsView = () => import(/* webpackChunkName: "admin" */ '@/views/admin/exames/ExamsView.vue');
const AuditView = () => import(/* webpackChunkName: "admin" */ '@/views/admin/audit/AuditView.vue');

// ============= Super =============
const SuperDashboard = () => import(/* webpackChunkName: "admin" */ '@/views/super/SuperDashboard.vue');

// ============= Relatório ==============
const ExamReportView = () => import(/* webpackChunkName: "report" */ '@/views/reports/ExamReportView.vue');
const SchedulesReportView = () => import(/* webpackChunkName: "report" */ '@/views/reports/SchedulesReportView.vue');

// ============= Navigation Guards ============
const adminNavigationGuard: NavigationGuard = (to, from, next) =>  vm.user != null ? next() : next({ name: 'login' });
const superNavigationGuard: NavigationGuard = (to, from, next) =>  vm.user?.roleDescription == "SUPER" ? next() : next({ name: 'admin' });

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: LoginMainView
    },
    {
        path: '/redirect/:accessToken/:proctoringId?',
        name: 'redirect',
        component: RedirectView
    },
    {
        path: '/',
        component: AdminMainPage,
        beforeEnter: adminNavigationGuard,
        children: [
            {
                path: '',
                name: 'admin',
                component: MenuPage
            },
            {
                path: 'exames',
                name: 'exames',
                component: ExamsView
            },
            {
                path: 'exame/:id',
                name: 'exame',
                component: AuditView
            }
        ]
    },
    {
        path: '/super',
        component: AdminMainPage,
        beforeEnter: superNavigationGuard,
        // beforeEnter: adminNavigationGuard,
        children: [
            {
                path: '',
                name: 'super',
                component: SuperDashboard
            },
        ]
    },
    // =============== RELATÓRIOS ============
    {
        path: '/exam-report/:id',
        name: 'exam-report',
        meta: {
            hideLibras: true,
            adminGuard: true
        },
        component: ExamReportView
    },
    {
        path: '/schedules-report',
        name: 'schedules-report',
        meta: {
            hideLibras: true,
            adminGuard: true
        },
        component: SchedulesReportView
    }

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
