import axios from 'axios';
import { formatISO } from 'date-fns';
import qs from 'qs';


const http = axios.create({
    timeout: 180000,
    paramsSerializer: params => qs.stringify(params, { serializeDate: (d) => formatISO(d) }),
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN_EXAM}`,
    }
});

// /easy-procto/agendamento/api/agendamentos/197/status/Executado

export default http;